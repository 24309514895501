.header {
  font-size: 0;
}

.header_top {
  background-color: #0b0b0b;
}

.header_top_content {
  height: 34px;
  justify-content: space-between;
  align-items: center;
}

.header_address {
  height: 20px;
  justify-content: start;
  align-items: center;
}

.header_address_icon {
  margin-right: 9px;
  margin-left: -3px;
  svg {
    width: 15px;
    height: 15px;
  }
}

.header_address_list {
  li {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    color: #fff;
    font-family: "Exo2-Regular";
    position: relative;
    padding-left: 9px;
    padding-right: 9px;

    &::after {
      content: '';
      position: absolute;
      height: 20px;
      width: 1px;
      background-color: #fff;
      opacity: .2;
      top: 0;
      right: 0;
    }

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;

      &:after {
        display: none;
      }
    }
  }
}



.header_phones {
  margin-left: 32px;
  justify-content: start;
  align-items: center;
  height: 20px;
}

.header_phones_icon {
  margin-right: 15px;
  svg {
    width: 15px;
    height: 15px;
  }
}

.header_phones_list {

  li {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 9px;
    padding-right: 9px;

    &::after {
      content: '';
      position: absolute;
      height: 20px;
      width: 1px;
      background-color: #fff;
      opacity: .2;
      top: 0;
      right: 0;
    }

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;

      &:after {
        display: none;
      }
    }

    a {
      font-size: 14px;
      color: #fff;
      font-family: "Exo2-Regular";
    }
  }
}




.header_bottom {
  background-color: #000;
  padding-bottom: 15px;
}

.header_bottom_content {
  justify-content: space-between;
}

.header_logo {
  padding-top: 10px;

  img {
    width: 163px;
  }
}

.header_nav_container {
  padding-top: 24px;
}

.header_nav_list {
  .header_nav_item {
    margin-right: 28px;

    &:last-child {
      margin-right: 0;
    }

    a {
      display: inline-block;
      height: 31px;
      line-height: 31px;
      color: #fff;
      font-size: 16px;
      font-family: "Exo2-Medium";
      text-transform: uppercase;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -2px;
        background-color: #f3e600;
        opacity: 0;
        transition: .3s;
      }

      &:hover {
        &:after {
          opacity: 1;
        }
      }
    }

    .call_back {
      width: 161px;
      text-align: center;
      background-color: #f3e600;
      font-size: 15px;
      font-family: "Exo2-Bold";
      border-radius: 3px;
      color: #000;
      transition: .3s;

      &::after {
        display: none;
      }

      &:hover {
        background-color: #d9bd00;
      }
    }
  }
}




#overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: .6;
  display: none;
}

.call_back_popup {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #fff;
  width: 380px;
  z-index: 10;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 10px;
  display: none;

  .custom_question_form {
    padding-top: 15px;
  }

  input {
    width: 100%;
    margin-bottom: 15px;
  }

  .chackbox {
    flex-wrap: nowrap;
  }
  .chackbox_false {
    width: 23px !important;
  }
  .chackbox_text {
    font-size: 9px;
  }

  button {
    margin-top: 15px;
    margin-bottom: 40px;
    width: 100% !important;
  }
}

.call_back_popup_title {
  text-transform: uppercase;
  font-size: 22px;
  color: #000;
  text-align: center;
  font-family: "Exo2-Bold";
  margin-top: 40px;
  margin-bottom: 20px;
}