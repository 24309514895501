.about_section {
  background-image: url(../../img/backgrounds/bg_about.png);
  background-position: left center;
  position: relative;
  padding-top: 87px;
  padding-bottom: 93px;

  .container {
    justify-content: center;
    position: relative;
    z-index: 10;
  }
}

.about_content {
  max-width: 960px;
  text-align: center;
}

.about_title {
  font-size: 36px;
  color: #fff;
  font-family: "Exo2-Bold";
  text-transform: uppercase;
  height: 81px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 6px;
    width: 52px;
    background-color: #f3e600;
  }
}

.about_text {
  font-size: 18px;
  color: #fff;
  font-family: "Exo2-Medium";
  line-height: 1.333;
  margin-top: 40px;
}

.uds_section {
  padding-top: 56px;
  padding-bottom: 56px;
}

.uds_photo {
  img {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
}

.map_section {
  height: 392px;
  margin-bottom: 13px;
}

#map {
  height: 100%;
  width: 100%;
}



.gm-style .gm-style-iw {
  left: 0 !important;
  width: 100% !important;

  &>div {
    width: 100% !important;
  }

  .markerInform {
    font-family: "Exo2-Medium";
    font-size: 13px !important;
    color: #000 !important;
    text-align: center !important;
    line-height: 1.3 !important;
    position: relative !important;
    padding-left: 25px;
    padding-right: 25px;
    margin: 0 auto;
  }
}

.gm-style {
  a {
    display: none !important;

    img {
      display: none !important;
    }
  }
}

.gmnoprint {
  display: none !important;
}

.gm-fullscreen-control {
  display: none !important;
}