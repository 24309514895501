.footer {
  position: relative;
  background-image: url(../../img/backgrounds/bg_footer.png);
  background-position: left center;
  font-size: 0;

  .container {
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 10;
    min-height: 313px;
  }
}

.footer_content {
  flex-direction: row;
  justify-content: space-between;
}

.footer_logo_container {
  width: 267px;
  padding-top: 43px;

  img {
    width: 225px;
  }
}

.footer_logo_text {
  color: #fff;
  font-family: "Exo2-Regular";
  font-size: 12px;
  line-height: 1.333;
  margin-top: 10px;
}

.footer_nav_container {
  width: 240px;
  padding-top: 49px;
}

.footer_title {
  font-size: 18px;
  color: #fff;
  font-family: "Exo2-Bold";
  text-transform: uppercase;
}

.footer_nav_list {
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 150px;
  margin-top: 24px;
}

.footer_nav_item {
  padding-bottom: 18px;
  min-width: 85px;

  &:nth-child(5) {
    align-self: flex-end;
  }
  &:nth-child(6) {
    align-self: flex-end;
  }

  a {
    font-size: 14px;
    color: #fff;
    font-family: "Exo2-Regular";
    text-transform: uppercase;
    opacity: .6;
    transition: .3s;

    &:hover {
      opacity: 1;
    }
  }
}


.footer_contact_container {
  padding-top: 49px;
  width: 235px;
}

.contact_list {
  margin-top: 24px;
}

.contact_item {
  margin-bottom: 15px;

  a {
    display: block;
    padding-left: 28px;
    font-size: 14px;
    color: #fff;
    font-family: "Exo2-Regular";
    position: relative;
    line-height: 1.286;
    padding-right: 50px;
    opacity: .8;
    transition: .3s;

    &:hover {
      opacity: 1;
    }

    i {
      position: absolute;
      left: 0;
      top: 3px;
      font-size: 19px;
    }
  }

  .fa-envelope {
    font-size: 14px;
  }

  .fa-phone {
    font-size: 19px;
  }
}


.copyright {
  height: 61px;
  border-top: 1px solid rgba(255, 255, 255, .2);
  text-align: center;
  font-size: 12px;
  font-family: "Exo2-Medium";
  color: #fff;
  text-transform: uppercase;
  padding-top: 23px;
  margin-top: 31px;

  a {
    color: #fff;
    text-decoration: underline !important;
  }
}