html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  position: relative;
}
body {
  overflow-x: hidden;
}

* {
  box-sizing: border-box; }

a {
  text-decoration: none !important;
}

ul {
  margin: 0;
  padding: 0; }

h1, h2, h3 {
  margin: 0;
  padding: 0;
  font-weight: normal; }

li {
  margin: 0;
  padding: 0;
  list-style: none; }

p {
  margin: 0;
}

input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

select {
  outline: none;
  border: none;
}

.clearfix::after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

section {
  font-size: 0;
}

.container {
  max-width: 1170px;
  margin: 0 auto;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.section_overlay {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  background-color: #000;
  opacity: .8;
  z-index: 5;
}

.hidden_photo {
  height: 10px;
  width: 10px;
  position: absolute;
  z-index: -10;
  opacity: 0;
}

.put_photo {
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
}
