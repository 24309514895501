.categories_list {
  position: relative;
  padding-bottom: 44px;
}

.categories_list_underlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 388px;
  background-color: #f2f2f2;
}

.categories_list_inform {
  padding-top: 80px !important;
  position: relative;
  z-index: 10;

  h3 {
    font-size: 36px;
    font-family: "Exo2-Bold";
    text-align: center;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 32px;

    &::after {
      content: '';
      position: absolute;
      width: 42px;
      height: 5px;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: #f3e600;
    }
  }

  .categories_list_text {
    width: 615px;
    margin: 0 auto;
    font-size: 16px;
    font-family: "Exo2-Regular";
    line-height: 1.25;
    text-align: center;
    margin-top: 34px;
  }
}


.categories_list_container {
  justify-content: center;
  padding-top: 70px;
  position: relative;
  z-index: 10;
}

.categories_ul {
  justify-content: space-between;
  width: 100%;
}

.categories_item {
  width: 274px;
  height: 432px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  border: 4px solid transparent;
  transform: .3s;

  &:hover {
    .categories_bottom {
      background-color: #d9bd00;

      span::after {
        border-color: transparent transparent transparent #d9bd00;
      }
    }

    .put_photo {
      transform: scale(1.05);
    }
  }

  a {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
}

.categories_item_overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  border: 4px solid transparent;
  transition: .1s;
  border-radius: 10px;
}

.categories_photo {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 66px;
  -webkit-background-size: cover;
  background-size: cover;
  transition: .3s;

  .put_photo {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    transition: .3s;
  }

  .cat_name {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .6);
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-family: "Exo2-Bold";
    text-transform: uppercase;
    padding-bottom: 9px;
    padding-top: 10px;
    padding-left: 6px;
    padding-right: 6px;
  }
}

.categories_bottom {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 66px;
  background-color: #f3e600;
  text-align: center;
  justify-content: center;
  align-items: center;
  transition: .3s;

  span {
    font-size: 24px;
    text-transform: uppercase;
    font-family: "Exo2-Regular";
    color: #000;
    padding-right: 18px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 0 8px 8px;
      border-color: transparent transparent transparent #000000;
    }

    &::after {
      content: '';
      position: absolute;
      right: 2px;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 0 7px 7px;
      border-color: transparent transparent transparent #f3e700;
      transition: .3s;
    }
  }
}





.paralax {
  height: 700px;
  background-image: url(../img/backgrounds/fireplace.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}





.priduct_list_top {
  padding-top: 80px !important;
}

.priduct_list_column {
  width: 480px;
  padding-right: 40px;

  &:first-child {
    margin-left: 40px;
    margin-right: 46px;
  }
}

.priduct_list_title {
  font-size: 36px;
  text-transform: uppercase;
  font-family: "Exo2-Bold";
  margin-bottom: 43px;
}

.priduct_list_text {
  font-size: 16px;
  line-height: 1.25;
}


.priduct_list_container {
  align-items: center;
  padding-top: 17px;
  padding-bottom: 80px;
}

.priduct_list_ul {
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
}

.priduct_list_item {
  width: 257px;
  margin-top: 53px;

  &:hover {
    .priduct_list_photo {
      &::after {
        background-color: #f3e600;
      }
      &::before {
        background-color: #f3e600;
      }
    }
    .product_list_name {
      text-decoration: underline !important;
    }
  }
}

.priduct_list_link {
  display: block;
}

.priduct_list_photo {
  display: block;
  -webkit-background-size: contain;
  background-size: contain;
  height: 238px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    height: 5px;
    left: -10px;
    width: 257px;
    top: -10px;
    background-color: #e6e6e6;
    transition: .3s;
  }

  &::after {
    content: '';
    position: absolute;
    width: 5px;
    left: -10px;
    height: 258px;
    top: -10px;
    background-color: #e6e6e6;
    transition: .3s;
  }
}

.product_list_bottom {
  padding-top: 18px;
  text-align: center;
  display: block;
  padding-left: 5px;
  padding-right: 5px;

  .product_list_name {
    font-size: 24px;
    font-family: "Exo2-Bold";
    color: #000;
    display: block;
    line-height: 1.167;
  }

  .product_list_price {
    display: block;
    font-size: 18px;
    font-family: "Exo2-Regular";
    color: #000;
    margin-top: 10px;
  }
}

.is_prod {
  .home_news {
    padding-bottom: 75px;
  }
}