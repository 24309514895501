.contact {
  padding-top: 98px;
  padding-bottom: 86px;
}

.contact_content {
  padding-top: 38px;
}

.contact_content_column {
  width: 50%;
  padding-right: 20px;

  .contact_item {
    font-size: 18px;
    color: #000;
    line-height: 1.2;
    margin-bottom: 8px;
  }

  .contact_list {
    margin-top: 14px;
  }

  a {
    font-size: 18px;
    color: #000;
    display: inline;
    padding-left: 0;
    padding-right: 0;
    transition: 0;
    opacity: 1;
  }
}

.contact_city {
  font-size: 24px;
  font-family: "Exo2-Bold";
}

.contact_item_1 {
  font-family: "Exo2-SemiBold";
}

.contact_item_2 {
  font-family: "Exo2-Regular";
}



.read_us {
  height: 394px;
}

.contact_page {
  .map_section {
    height: 515px !important;
  }
}







.delivery_main {
  background-color: #f0f0f0;
  padding-top: 37px;
  padding-bottom: 25px;
  margin-top: 50px;

  .container {
    justify-content: center;
  }
}

.accordeon__container {
  width: 750px;
}

.accordeon__item {
  padding-bottom: 8px;
  transition: .8s;

  &:last-child {
    padding-bottom: 0;
  }
}

.accordeon__title {
  background-color: #fff;
  padding-left: 43px;
  padding-right: 65px;
  align-items: center;
  min-height: 111px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  font-size: 30px;
  text-transform: uppercase;
  color: #000;
  font-family: "Exo2-Medium";
  justify-content: start;
  position: relative;
  margin-bottom: 9px;

  &:hover {
    .accordeon__title_arrow {
      &::before {
        border-color: #f3e600 transparent transparent transparent;
      }
    }
  }
}

.accordeon__title_arrow {
  position: absolute;
  width: 28px;
  height: 28px;
  right: 22px;
  top: 41px;
  transition: .8s;

  &::before {
    content: '';
    position: absolute;
    top: 7px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 14px 14px 0 14px;
    border-color: #000 transparent transparent transparent;
  }

  &::after {
    content: '';
    position: absolute;
    top: 7px;
    left: 5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 9px 0 9px;
    border-color: #fff transparent transparent transparent;
  }
}

.accordeon__item {
  &.active {
    padding-bottom: 17px;

    .accordeon__title_arrow {
      transform: rotateX(-180deg);

      &::before {
        border-color: #f3e600 transparent transparent transparent;
      }
    }
  }
}


.accordeon__text {
  background-color: #fff;
  display: none;
  margin-top: 9px;
}

.accordeon__text_inner {
  padding: 26px;
}

.del_table {
  border: 2px solid #000;
  border-top: none;
}

.del_thed {
  align-items: stretch;
  border: none;
  background-color: #000;

  .del_thed_part {
    width: calc(100% / 3);
    background-color: #000;
    color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 12px;
    padding-right: 5px;
    font-size: 18px;
    font-family: "Exo2-Medium";
    border: none;
  }
}

.del_row {
  align-items: stretch;
  border-bottom: 1px solid rgba(0, 0, 0, .2);

  &:last-child {
    border: none;
  }

  .del_col {
    width: calc(100% / 3);
    background-color: #fff;
    color: #000;
    padding-top: 5px;
    padding-bottom: 6px;
    padding-left: 12px;
    padding-right: 6px;
    font-size: 14px;
    font-family: "Exo2-Medium";
    border: none;
    border-right: 1px solid rgba(0, 0, 0, .2);

    &:last-child {
      border: none;
    }
  }
}