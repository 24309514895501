.main_screen {
  background-color: #000;
  background-image: none;
  background-repeat: no-repeat;
}

.main_screen_bg {
  background-image: url(../img/decor/fireplace.png);
}



.main_screen_content {
  padding-top: 140px;
  padding-left: 90px;
  padding-bottom: 295px;
}

.main_title {
  font-size: 60px;
  color: #fff;
  font-family: "Exo2-Bold";
  text-transform: uppercase;
  line-height: 1;

  span {
    font-size: 48px;
    font-family: "Exo2-Regular";
  }
}


.change_prod_container {
  padding-top: 47px;
}

.change_prod_title {
  font-size: 24px;
  color: #fff;
  font-family: "Exo2-Regular";
}

.change_prod_form {
  padding-top: 29px;
}

.change_prod_input {
  height: 75px;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  margin-right: 11px;
}

.readyChange {
  cursor: pointer;

  &:hover {
    .change_prod_arrow {
      &::before {
        border-color: #f3e600 transparent transparent transparent;
      }
    }
  }
}

.change_prod_input_main {
  &.active {
    .change_prod_arrow {
      &::before {
        border-color: #f3e600 transparent transparent transparent;
      }
    }
  }
}

.change_click {
  width: 100%;
  height: 100%;
}

.change_prod {
  .products_of_cat {
    display: block;
  }
}

.change_category {
  width: 348px;
}

.change_prod {
  width: 424px;
}

.change_prod_hint {
  font-size: 14px;
  color: #000;
  font-family: "Exo2-Regular";
  opacity: .6;
  padding-top: 9px;
  padding-left: 15px;
}

.changed_prod {
  font-size: 18px;
  color: #000;
  font-family: "Exo2-Regular";
  padding-left: 15px;
  text-transform: uppercase;
  margin-top: 10px;
  margin-right: 43px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  opacity: .6;
}

.change_prod_arrow {
  position: absolute;
  right: 16px;
  top: 46px;
  height: 6px;
  width: 12px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: #000000 transparent transparent transparent;
    transition: .3s;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 2px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: #fff transparent transparent transparent;
  }
}

.change_prod_button {
  width: 135px;
  height: 76px;
  border-radius: 7px;
  background-color: rgb(243, 230, 0);
  font-size: 16px;
  color: #000;
  font-family: "Exo2-Bold";
  text-align: center;
  line-height: 76px;
  text-transform: uppercase;
  transition: .3s;

  &:hover {
    background-color: #d9bd00;
  }
}



.main_categorees_container {
  margin-top: 38px;
}

.main_categorees_list {
  padding-top: 29px;
}

.main_categorees_item {
  margin-right: 8px;

  a {
    display: inline-block;
    height: 52px;
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    padding-left: 16px;
    padding-right: 16px;
    line-height: 52px;
    font-size: 18px;
    color: #000;
    font-family: "Exo2-Regular";
    text-transform: uppercase;
    transition: .3s;

    &:hover {
      background-color: #f3e600;
    }
  }
}



.main_screen_other {
  background-image: url(../img/backgrounds/main_other.png);
  background-position: top center;
  -webkit-background-size: cover;
  background-size: cover;

  .container {
    align-items: center;
    height: 373px;
  }

  .main_screen_other_content {
    h2 {
      font-size: 60px;
      font-family: "Exo2-Bold";
      text-transform: uppercase;
      color: #fff;
      padding-left: 100px;

      span {
        font-size: 48px;
        font-family: "Exo2-Regular";
      }
    }
  }
}



.change_prod_input_list {
  position: absolute;
  width: 100%;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 15.36px 0.64px rgba(0, 0, 0, 0.1);
  left: 0;
  top: 84px;
  padding-top: 7px;
  padding-bottom: 15px;
  padding-left: 17px;
  padding-right: 17px;
  display: none;
}

.change_prod_link {
  display: block;
  font-size: 18px;
  text-transform: uppercase;
  font-family: "Exo2-Regular";
  color: #000;
  padding-top: 7px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, .1);

  &:hover {
    text-decoration: underline !important;
  }
}

.change_prod_input_item {
  &:last-child {
    .change_prod_link {
      border: none;
    }
  }
}

.products_of_cat {
  display: none;
}

.opNone {
  opacity: 1 !important;
}