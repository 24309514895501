.advantages {
  height: 304px;

  .container {
    height: 100%;
    position: relative;
  }
}

.advantages_block {
  min-height: 461px;
  background-color: #fff;
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 0;
  padding-top: 71px;
  padding-left: 40px;
  padding-right: 40px;
  box-shadow: 0px 5px 35.34px 2.66px rgba(0, 0, 0, 0.1);
}

.advantages_title {
  text-align: center;
  text-transform: uppercase;
  font-size: 36px;
  color: #000;
  font-family: "Exo2-Bold";
}


.advantages_list {
  justify-content: space-between;
  padding-top: 70px;
}

.advantages_item {
  justify-content: space-between;
  width: 310px;
  margin-bottom: 70px;

  &:last-child {
    .advantages_item_title {
      font-size: 22px;
    }
  }
}

.advantages_icon {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #e9e9e9;
  position: relative;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.advantages_text {
  width: 230px;
}

.advantages_item_title {
  font-size: 18px;
  font-family: "Exo2-Bold";
  text-transform: uppercase;
}

.advantages_text_info {
  font-size: 14px;
  font-family: "Exo2-Regular";
  line-height: 1.214;
  margin-top: 10px;
}
