.home_news {
  padding-top: 86px;
  padding-bottom: 156px;
}

.home_news_content {
  flex-direction: column;
  justify-content: space-between;
  align-self: baseline;
  align-content: space-between;
  height: 684px;
  flex-wrap: wrap;
}

.news_item {
  width: 267px;
  height: 331px;
  position: relative;
  border-radius: 5px;
  background-color: rgb(240, 240, 240);
  overflow: hidden;
  box-shadow: 5px 5px 16px 0px rgba(0, 0, 0, 0.1);

  &:hover {
    .news_title {
      text-decoration: underline !important;
    }
  }
}

.news_item_bottom {
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 12px;
}

.news_item_top {
  height: 162px;
  display: block;
}

.news_item_title_container {
  align-items: center;
  flex-wrap: nowrap;
}

.news_item_date {
  height: 50px;
  width: 40px;
  position: absolute;
  left: 18px;
  top: 18px;

  div {
    height: 50%;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: "Exo2-Bold";
    width: 100%;
  }
}

.news_item_date_top {
  background-color: #ffed00;
}

.news_item_date_bottom {
  background-color: #fff;
  font-family: "Exo2-Regular" !important;
  text-transform: uppercase;
}

.news_title {
  font-size: 16px;
  color: #000;
  font-family: "Exo2-Bold";
  line-height: 1.25;
  max-height: 41px;
  overflow: hidden;
}

.news_text {
  position: absolute;
  top: 223px;
  left: 18px;
  right: 18px;
  bottom: 18px;
  overflow: hidden;
  display: block;
  font-size: 14px;
  font-family: "Exo2-Regular";
  line-height: 1.286;
  color: #000;
}

.home_news_buttons {
  display: none;
}


.main_news {
  width: 558px;
  height: 100%;

  .news_item_top {
    height: 331px;
  }

  .news_item_bottom {
    padding-left: 29px;
    padding-right: 29px;
    padding-top: 22px;
  }

  .news_item_date {
    position: static;
    margin-right: 21px;
  }

  .news_title {
    font-size: 24px;
    display: inline-block;
    line-height: 1.20;
    max-height: 60px;
  }

  .news_item_date {
    flex: 0 1 65px;
    height: 67px;

    div {
      font-size: 18px;
    }
  }

  .news_text {
    position: static;
    margin-top: 15px;
    height: 155px;
    font-size: 16px;
    font-family: "Exo2-Regular";
    line-height: 1.375;
  }

  .home_news_buttons {
    align-items: center;
    padding-top: 26px;
    display: flex;

    .home_news_button {
      width: 161px;
      height: 39px;
      line-height: 39px;
      border-radius: 5px;
      background-color: rgb(255, 237, 0);
      text-align: center;
      font-size: 16px;
      text-transform: uppercase;
      font-family: "Exo2-Bold";
      color: #000;
      transition: .3s;

      &:hover {
        background-color: #d9bd00;
      }

      &:last-child {
        background-color: transparent;

        &:hover {
          color: #d9bd00;
        }
      }
    }
  }
}




.all_news {
  padding-bottom: 20px;

  .home_news_content {
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    height: auto;
    flex-wrap: wrap;
    margin-top: 40px;
  }

  .news_item {
    margin-bottom: 24px;
  }
}



.one_news {
  padding-top: 80px;
}

.one_news_photo {
  float: left;
  width: 653px;
  margin-right: 35px;

  img {
    width: 100%;
  }
}

.one_news_title {
  font-size: 24px;
  color: #000;
  font-family: "Exo2-Bold";
  line-height: 1.25;
  padding-top: 12px;
  padding-bottom: 14px;
}

.one_news_text {
  font-size: 16px;
  color: #000;
  font-family: "Exo2-Regular";
  line-height: 1.375;
}

.one_news_bottom {
  justify-content: space-between;
  align-items: center;
  padding-top: 29px;
}

.one_news_bottom_count {
  align-items: center;
}

.view_news {
  font-size: 16px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, .6);
  font-family: "Exo2-Regular";
  margin-right: 35px;

  span {
    color: rgba(0, 0, 0, 1);
  }
}

.like_news {
  opacity: .6;
  transition: .3s;
  cursor: pointer;

  img {
    display: inline-block;
    vertical-align: middle;
  }

  &:hover {
    opacity: 1;
  }

  span {
    font-size: 16px;
    text-transform: uppercase;
    color: #000;
    font-family: "Exo2-Regular";
    display: inline-block;
    vertical-align: middle;
    margin-left: 7px;
  }
}

.one_news_button {
  height: 39px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  background-color: #f3e600;
  font-size: 16px;
  font-family: "Exo2-Bold";
  border-radius: 3px;
  color: #000;
  text-transform: uppercase;
  line-height: 39px;
  transition: .3s;

  &:hover {
    background-color: #d9bd00;
  }
}