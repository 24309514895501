.product_cart {
  padding-top: 80px;
}

.product_cart_container {
  justify-content: space-between;
}

.product_cart_photo_container {
  width: 363px;
  padding-top: 10px;
}

.product_cart_big_photo {
  position: relative;
}

.product_cart_big_inner {
  position: relative;
  z-index: 10;
  width: 363px;
  height: 485px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: contain;
  background-size: cover;
  border-radius: 10px;
  transition: .5s;
}


.product_cart_inderlay {
  position: absolute;
  width: 342px;
  left: -9px;
  top: -10px;
  height: 468px;
  background-color: #f3e600;
}

.product_cart_photos_list {
  width: 100%;
  justify-content: space-between;
  margin-top: 11px;
}

.product_cart_photos_item {
  width: 80px;
  height: 113px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;

  &:hover {
    .product_cart_photos_hover {
      opacity: 1;
    }
  }

  &.active {
    .product_cart_photos_hover {
      opacity: 1;
    }
  }
}

.product_cart_photos_hover {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 5px;
  border: 3px solid #f3e600;
  transition: .3s;
  opacity: 0;

  &::before {
    content: '';
    position: absolute;
    top: -9px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5.5px 6px 5.5px;
    border-color: transparent transparent #f3e700 transparent;
  }
}


.product_cart_desc {
  width: 685px;
}

.product_cart_name {
  text-transform: uppercase;
  font-size: 30px;
  font-family: "Exo2-Bold";
  text-align: left;
}

.product_cart_desc_order {
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  padding-bottom: 23px;
  border-bottom: 1px solid #cccccc;

  span {
    text-transform: uppercase;
    font-size: 16px;
    color: #000;
    font-family: "Exo2-Medium";
  }

  .call_back {
    width: 161px;
    text-align: center;
    background-color: #f3e600;
    font-size: 15px;
    font-family: "Exo2-Bold";
    border-radius: 3px;
    color: #000;
    height: 31px;
    line-height: 31px;
    text-transform: uppercase;
    font-size: 15px;
    transition: .3s;
    
    &:hover {
      background-color: #d9bd00;
    }
  }
}

.product_cart_number {
  padding-left: 11px;
  padding-right: 11px;
  background-color: #000;
  color: #f3e600;
  font-size: 16px;
  text-transform: uppercase;
  font-family: "Exo2-Medium";
  padding-top: 8px;
  padding-bottom: 7px;

  a {
    color: #f3e600;
    font-size: 16px;
    text-transform: uppercase;
    font-family: "Exo2-Medium";
  }
}


.product_desc_container {
  margin-top: 19px;
}

.product_desc_title {
  font-size: 18px;
  font-family: "Exo2-Bold";
  text-transform: uppercase;
}

.product_desc {
  font-size: 16px;
  font-family: "Exo2-Regular";
  line-height: 1.25;
  padding-right: 15px;

  p {
    margin-top: 22px;

    &:first-child {
      margin-top: 12px;
    }
  }
}