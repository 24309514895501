.information {
  padding-top: 80px;
  padding-bottom: 100px;
}

.information_title {
  font-size: 30px;
  color: #000;
  text-transform: uppercase;
  font-family: "Exo2-Bold";
  position: relative;
  padding-bottom: 41px;

  &::after {
    content: '';
    position: absolute;
    width: 52px;
    height: 6px;
    bottom: 0;
    left: 0;
    background-color: #f1e502;
  }
}

.information_content {
  justify-content: space-between;
}

.information_left {
  width: 590px;
  padding-top: 38px;
}

.information_part {
  margin-bottom: 35px;

  &:last-child {
    margin-bottom: 0;
  }
}

.information_part_title {
  font-size: 24px;
  font-family: "Exo2-Medium";
}

.information_part_text {
  font-size: 18px;
  font-family: "Exo2-Regular";
  padding-top: 14px;
  line-height: 1.333;
}

.information_right {
  width: 473px;
}

.information_right_photo_cont {
  position: relative;
  width: 473px;
  height: 554px;
  margin-top: 27px;

  .man {
    position: absolute;
    z-index: 20;
    bottom: 0;
    left: 80px;
  }
}

.information_right_underlay {
  position: absolute;
  height: 541px;
  width: 458px;
  left: -6px;
  top: -5px;
  background-color: #f3e600;
}

.information_right_photo {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: 3px;
  background-image: url(../img/decor/info.png);
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}



.questions {
  padding-top: 100px;
  background-color: #f0f0f0;
  padding-bottom: 110px;
}

.questions_content {
  padding-top: 35px;
  justify-content: space-between;
}

.questions_left {
  width: 588px;
  position: relative;
}

.questions_item {
  border-radius: 5px;
  background-color: #fff;
  font-size: 24px;
  color: #000;
  text-transform: uppercase;
  font-family: "Exo2-Medium";
  padding-left: 15px;
  padding-right: 5px;
  padding-top: 13px;
  padding-bottom: 12px;
  cursor: pointer;
  transition: .3s;
  margin-bottom: 8px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  img {
    display: inline-block;
  }

  &::after {
    content: '';
    position: absolute;
    right: -8px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 0 8px 8px;
    border-color: transparent transparent transparent #f1e502;
    opacity: 0;
    transition: .3s;
  }

  &:hover {
    background-color: #f1e502;

    &::after {
      opacity: 1;
    }
  }
  &.active {
    background-color: #f1e502;

    &::after {
      opacity: 1;
    }
  }
}

.answer {
  display: none;
}

.questions_right {
  width: 453px;
  margin-right: 70px;
  position: relative;
  overflow: hidden;
}

.answer_for_quest {
  background-color: #fff;
  border-radius: 5px;
  padding-left: 22px;
  padding-right: 22px;
  line-height: 1.501;
  font-size: 16px;
  color: #000;
  transition: .5s;
  position: absolute;
  width: 455px;
  left: 0;
  overflow: auto;
}

.answer_for_quest_inner {
  padding-top: 20px;
  padding-bottom: 20px;
}





.custom_question {
  position: relative;
  padding-bottom: 10px;
}

.custom_question_underlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 123px;
  background-color: #efefef;
}

.custom_question_content {
  width: 1143px;
  box-shadow: 0px 5px 35.34px 2.66px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding-top: 74px;
  padding-left: 98px;
  padding-right: 98px;
  padding-bottom: 82px;
  position: relative;
  z-index: 10;

  .information_title {
    text-align: center;

    &::after {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}



.custom_question_form {
  justify-content: space-between;
  padding-top: 33px;
  align-items: center;

  button {
    width: 176px;
    height: 41px;
    line-height: 41px;
    border-radius: 5px;
    background-color: rgb(255, 237, 0);
    font-size: 18px;
    font-family: "Exo2-Bold";
    outline: none;
    border: none;
    cursor: pointer;
    transition: .3s;

    &:hover {
      background-color: #d9bd00;
    }
  }
}

.short_input {
  width: 297px;
  height: 37px;
  border-radius: 5px;
  background-color: rgb(240, 240, 240);
  font-size: 18px;
  font-family: "Exo2-Regular";
  padding-left: 15px;
  padding-right: 9px;
  margin-bottom: 28px;
}

.textarea {
  width: 100%;
  height: 126px;
  border-radius: 5px;
  background-color: rgb(240, 240, 240);
  font-size: 18px;
  font-family: "Exo2-Regular";
  padding-left: 15px;
  padding-right: 9px;
  padding-top: 10px;
  margin-bottom: 28px;
}

.chackbox {
  align-items: center;
  cursor: pointer;
  
  &.active .chackbox_false {
    background-color: #000;
    background-image: url(../img/icons/fly.png);
    background-repeat: no-repeat;
    background-position: center center;
  }

  input {
    display: none;
  }
}


.chackbox_false {
  border-radius: 2px;
  background-color: rgb(240, 240, 240);
  width: 23px;
  height: 23px;
}

.chackbox_text {
  text-transform: uppercase;
  color: #000;
  font-size: 18px;
  font-family: "Exo2-Regular";
  margin-left: 10px;
}